import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper/modules';
import "swiper/css"
import 'swiper/css/thumbs';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageModal from './image-modal';

const AdventureGallery = ({ zoneContent, isGalleryModalOpen, setIsGalleryModalOpen, sectionThemeObj }) => {
    const sectionContentObj = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [details, setDetails] = useState([])
    const [filter, setFilter] = useState('all')
    const [cell, setCell] = useState(null)
    const [isHover, setIsHover] = useState(false)
    const [isActiveTitle, setIsActiveTitle] = useState("")
    const [galleryData, setGalleryData] = useState(false);
    const [isActiveVideo, setIsActiveVideo] = useState(false);

    const frameImage = {
        beforeAfter: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: `url(${sectionContentObj?.frameImage?.url}) 100% center/cover no-repeat`,
        }
    };
    const activeImage = {
        beforeAfter: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: `url(${sectionContentObj?.activeFrame?.url}) 100% center/cover no-repeat`,
        }
    };
    useEffect(() => {
        if (filter !== 'all') {
            const filteredData = sectionContentObj?.dynamicFieldList?.filter(image => image.parentName === filter)
            setDetails(filteredData)
        } else setDetails(sectionContentObj?.dynamicFieldList)
    }, [filter])

    return (
        <>
            <div id='gallery-page' className='section' style={{
                background: `url(${sectionContentObj?.backgroundImage?.url}) 50% no-repeat`,
                backgroundSize: "100% 100%"
            }}>
                <img src={sectionContentObj?.mobileBackgroundImage?.url} className='gallery-mobile-background' />
                <div className='gallery-section'>
                    <div className='title-list'>
                        {
                            sectionContentObj?.lists.map((title, i) => (
                                <div key={`title${i}`} className='title-cell' onClick={() => setFilter(title.name.toLowerCase())}>
                                    <img className='title' src={sectionContentObj?.cellImage?.url} />
                                    <img className={`title title-hover ${filter === title.name.toLowerCase() || isActiveTitle === title.name.toLowerCase() ? 'isActive' : ''} `} src={sectionContentObj?.activeCell?.url} />
                                    <span className='btn-text' style={{ color: sectionThemeObj ? sectionThemeObj?.title : "" }} onMouseOver={() => setIsActiveTitle(title.name.toLowerCase())} onMouseLeave={() => setIsActiveTitle("")}>{title.name.toUpperCase()}</span>
                                </div>
                            ))
                        }
                    </div>
                    <Swiper
                        slidesPerView={'auto'}
                        className='gallery-swiper'
                        grabCursor={false}
                        spaceBetween={50}
                    >
                        {details?.map((image, i) => (
                            <SwiperSlide key={`Feature${i}`}>
                                <div onMouseOver={() => {
                                    setIsHover(true)
                                    setCell(i)
                                }} onMouseLeave={() => setIsHover(false)} className='thumbnail-cell'
                                    onClick={() => {
                                        if (image?.image) {
                                            setGalleryData(image.image)
                                            setIsActiveVideo(false)
                                        } else {
                                            setIsActiveVideo(image?.video?.url)
                                            setGalleryData(false)
                                        }
                                        setIsGalleryModalOpen(true)
                                    }}
                                >
                                    {image?.image ? <img className='cell-image' src={image?.image?.url} width={"100%"} /> : <iframe className='cell-image' src={image?.video?.url} width={"100%"} />}
                                    <div className='cell-text'>
                                        <img className='left-icon' src={sectionContentObj?.leftIcon?.url} />
                                        <span className='description' style={{ color: sectionThemeObj ? `${sectionThemeObj?.description}` : "" }}>{image?.image ? image?.image?.name : image.video.name}</span>
                                        <img className='right-icon' src={sectionContentObj?.rightIcon?.url} />
                                    </div>
                                    <div style={isHover && cell === i ? activeImage.beforeAfter : frameImage.beforeAfter}></div>
                                </div>
                            </SwiperSlide>
                        ))
                        }
                    </Swiper>
                    <Swiper
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[Thumbs]}
                        className="mobile-gallery-swiper"
                    >
                        {details?.map((image, i) => (
                            <SwiperSlide key={`Feature${i}`}>
                                <div className='thumbnail-cell'>
                                    {image?.image ? <img className='cell-image' src={image?.image?.url} width={"100%"} /> : <iframe className='cell-image' src={image?.video?.url} width={"100%"} />}
                                    <div className='cell-text'>
                                        <img className='left-icon' src={sectionContentObj?.leftIcon?.url} />
                                        <span className='description' style={{ color: sectionThemeObj ? `${sectionThemeObj?.description}` : "" }}>{image?.image ? image?.image?.name : image?.video?.name}</span>
                                        <img className='right-icon' src={sectionContentObj?.rightIcon?.url} />
                                    </div>
                                </div>
                            </SwiperSlide>))
                        }
                    </Swiper>
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        slidesPerView={'auto'}
                        modules={[Thumbs]}
                        className="mobile-gallery-thumb-swiper"
                    >
                        {details?.map((data, i) => (<SwiperSlide key={`Feature${i}`}>
                            <div className='thumb'>
                                {data?.image ? <img src={data?.image?.url} /> : <iframe src={data?.video?.url} />}
                            </div>
                        </SwiperSlide>))
                        }
                    </Swiper>
                </div>
            </div>
            {galleryData && isGalleryModalOpen && <ImageModal
                isOpen={isGalleryModalOpen}
                setOpen={() => setIsGalleryModalOpen(false)}
                size={"lg"}
                centered
                className='image-modal'
                message={galleryData}
            />}
            {isActiveVideo && isGalleryModalOpen && (
                <Modal
                    isOpen={isGalleryModalOpen}
                    toggle={() => setIsGalleryModalOpen(false)}
                    size={"lg"}
                    centered
                    className='image-modal'
                >
                    <button onClick={() => setIsGalleryModalOpen(false)} class="modal-video-close-btn" aria-label="Close the modal by clicking here"></button>
                    <div className='d-flex flex-column align-items-center '>
                        <iframe width="100%" height="450"
                            src={isActiveVideo}>
                        </iframe>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default AdventureGallery