import React, { useState } from 'react'
import { toast } from 'react-toastify';
import '../assets/css/zweihander.css';
import GetEmail from "./getEmail";

const SectionOne = ({ zoneContent, reforgedMenu, sectionThemeObj }) => {
    const sectionContentObj = zoneContent[0]?.ContentDTO?.ContentObject && JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)
    const [isDisplay, setIsDisplay] = useState(false)
    const [isAddEmail, setIsAddEmail] = useState(false)
    const backImage = {
        backgroundImage: `url(${reforgedMenu?.shareLinksBackground?.url})`
    }

    const copyLink = () => {
        navigator.clipboard.writeText(window.location.toString());
        toast('Copied successfully!')
    }

    return (
        <>
            <div id="home-page" className={`section`} onClick={() => { isDisplay && setIsDisplay(false) }}>
                {sectionContentObj?.video ? <video id="myVideo" loop muted data-autoplay playsInline>
                    <source src={sectionContentObj?.video?.url} type="video/mp4" />
                </video> : sectionContentObj?.image ?
                    <img className='home-page-background' src={sectionContentObj?.image?.url} alt="" /> : ""}
                <img className='home-page-mobile-background' src={sectionContentObj?.mobileBackground?.url} alt="" />
                <div className="share-icon" onClick={() => { setIsDisplay(!isDisplay) }}>
                    <img src={reforgedMenu?.shareIcon?.url} alt="" />
                </div>
                <div className="share-links" style={{ ...(!isDisplay && { display: 'none' }) }}>
                    <div className="share-links-block" style={backImage}>
                        {reforgedMenu?.images?.map((links, i) => {
                            if (links?.name.toLowerCase().split(' ').join('') === 'copylink' || links?.href.toLowerCase().split(' ').join('') === 'copylink') {
                                return <div key={`link_${i}`} className="link-block" onClick={copyLink}>
                                    <div className="link-icon">
                                        <img src={links?.url} alt="" />
                                    </div>
                                    <div className="link-name" style={{ color: sectionThemeObj ? sectionThemeObj?.linkFont : "" }}>{links?.name}</div>
                                </div>
                            } else {
                                return <a key={`link_${i}`} className='w-100' href={links?.href} target='_blank'>
                                    <div className="link-block">
                                        <div className="link-icon">
                                            <img src={links?.url} alt="" />
                                        </div>
                                        <div className="link-name" style={{ color: sectionThemeObj ? sectionThemeObj?.linkFont : "" }}>{links?.name}</div>
                                    </div>
                                </a>
                            }
                        })
                        }
                    </div>
                </div>
                <div className="layer d-flex flex-column justify-content-center align-items-center">
                    <div className='main-logo-section'>
                        <img className='home-zweihander-logo' src={sectionContentObj?.upperMainImage?.url} alt={sectionContentObj?.upperMainImage?.name} />
                    </div>
                    <div className='slogan-description mt-3'>
                        <div className='slogan-text'><h5 className='slogan' style={{ color: sectionThemeObj ? `${sectionThemeObj?.title}` : "" }}>{sectionContentObj?.subTitle.toUpperCase()}</h5></div>
                        {((typeof sectionContentObj?.emailCollector === "string") && (sectionContentObj?.emailCollector?.toLowerCase() === "true")) ?
                            <a className='mb-2' onClick={() => setIsAddEmail(sectionContentObj?.downloadImage?.href)} target='_blank'>
                                <img src={sectionContentObj?.downloadImage?.url} className="download-image" />
                            </a> :
                            <a className='mb-2' href={sectionContentObj?.downloadImage?.href} target='_blank'>
                                <img src={sectionContentObj?.downloadImage?.url} className="download-image" />
                            </a>}
                        <a className='d-flex justify-content-center' href={sectionContentObj?.images?.[0]?.href} target='_blank'>
                            <img src={sectionContentObj?.images?.[0]?.url} className="search-image" />
                        </a>
                    </div>
                    <div className='btn-group'>
                        {sectionContentObj?.images?.length ? sectionContentObj?.images.map((image, i) => <a key={`image_${i}`} href={image?.href} target='_blank'><img className={image?.name.toLowerCase().includes('search') ? "btn-search" : ""} src={image?.url} alt={image?.name} width={"20%"} /></a>) : ''}
                    </div>
                </div>
            </div>
            {isAddEmail && <GetEmail
                isOpen={isAddEmail}
                setIsOpen={() => setIsAddEmail(false)}
            />}
        </>
    )
}

export default SectionOne