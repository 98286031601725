import React, { useState, useEffect } from "react";
import SliderOne from "@/components/slider-one";
import ProductLines from "@/components/productLines";
import AboutTwo from "@/components/about-two";
import SubscribeForm from "@/components/subscribe-form";
import PageBanner from "@/components/page-banner";
import Terrain from "@/components/epics/terrain";
import Publications from "@/components/epics/publications";
import StoryTeller from "@/components/story-teller";
import JoinNow from "@/components/join-now";
import SectionOne from "@/components/section-one";
import NewsSection from "@/components/news-section";
import CharacterSection from "@/components/character-section";
import AdventureExplore from "@/components/adventure-explore";
import AdventureGameFeature from "@/components/adventure-game-feature";
import AdventureGallery from "@/components/adventure-gallery";
import ZweihanderFooter from "@/components/zweihander-footer";
import DungeonEpics from "@/components/epics/dungeonEpics";
import Channels from "@/components/epics/channels";
import Portfolio from "@/components/epics/portfolio";
import ScenicQuote from "@/components/epics/scenicQuote";
import TestimonialsOneCarousel from "@/components/testimonials-carousel";
import TeamCarousel from "@/components/team-carousel";
import VideoOne from "@/components/video-one";
import ContactInfos from "@/components/contact-infos";
import Characters from "@/components/epics/characters";
import Survey from "@/components/publisher/surveyForm/Survey";
import CallToActionOne from "@/components/call-to-action-one";
import DisqusPage from "@/components/disqus";
import Licensing from "@/components/licensing";
import ProductCatalog from "@/components/product-catalog";
import GoogleMap from "@/components/google-map";
import Publication from "@/components/epics/publication";
import PrivacyPolicy from "@/components/privacy-policy";

const GlobalComponent = ({
  displayData,
  reforgedMenu,
  sectionThemeObj,
  className,
  activeSection,
  blogContent,
  surveyFormData,
  isGameFeatureModalOpen,
  setIsGameFeatureModalOpen,
  isGalleryModalOpen,
  setIsGalleryModalOpen,
  isExploreeModalOpen,
  setIsExploreeModalOpen,
}) => {
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  const Components = {
    SliderOne: SliderOne,
    AboutTwo: AboutTwo,
    ProductLines: ProductLines,
    Characters: Characters,
    SubscribeForm: SubscribeForm,
    PageBanner: PageBanner,
    SectionOne: SectionOne,
    NewsSection: NewsSection,
    CharacterSection: CharacterSection,
    AdventureExplore: AdventureExplore,
    AdventureGameFeature: AdventureGameFeature,
    AdventureGallery: AdventureGallery,
    ZweihanderFooter: ZweihanderFooter,
    Terrain: Terrain,
    Publications: Publications,
    StoryTeller: StoryTeller,
    JoinNow: JoinNow,
    TeamCarousel: TeamCarousel,
    TestimonialsOneCarousel: TestimonialsOneCarousel,
    VideoOne: VideoOne,
    ContactInfos: ContactInfos,
    Survey: Survey,
    CallToActionOne: CallToActionOne,
    DisqusPage: DisqusPage,
    Licensing: Licensing,
    ProductCatalog: ProductCatalog,
    GoogleMap: GoogleMap,
    Publication: Publication,
    PrivacyPolicy: PrivacyPolicy,
    // Dungeon Epics
    DungeonEpics: DungeonEpics,
    Channels: Channels,
    Portfolio: Portfolio,
    ScenicQuote: ScenicQuote
  };

  const props = {
    CallToActionOne: { extraClassName: "ready" },
    GoogleMap: { extraClass: "contact-page" },
  };

  return (
    <>
      {
        displayData && typeof Components[displayData.ComponentDTO?.Name] !== "undefined"
          ? React.createElement(Components[displayData.ComponentDTO?.Name], {
            className,
            reforgedMenu,
            sectionThemeObj,
            zoneContent: [displayData],
            activeSection,
            blogContent,
            surveyFormData,
            isGameFeatureModalOpen,
            setIsGameFeatureModalOpen,
            isGalleryModalOpen,
            setIsGalleryModalOpen,
            isExploreeModalOpen,
            setIsExploreeModalOpen,
            ...(props[displayData.ComponentDTO?.Name] && props[displayData.ComponentDTO?.Name]),
          })
          : null
      }
    </>
  );
};

export default GlobalComponent;
