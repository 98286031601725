import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import moment from "moment";
import '../assets/css/zweihander.css';

const NewsSection = ({ zoneContent, blogContent, sectionThemeObj }) => {
    const sectionContentObj = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)
    const [latestBlogs, setLatestBlogs] = useState([])

    useEffect(() => {
        let updatedBlogs = []
        sectionContentObj?.insertBlog?.forEach(oldBlog => {
            blogContent?.forEach(newBlog => {
                if (oldBlog.Id === newBlog.Id) {
                    updatedBlogs.push({
                        ...oldBlog,
                        Blog: newBlog?.ContentTagDTO?.[0]?.ContentDTO?.ContentObject && JSON.parse(newBlog?.ContentTagDTO?.[0]?.ContentDTO?.ContentObject)
                    })
                    return
                }
            })
        })
        const sortedBlogs = updatedBlogs.sort((a, b) => b.Id - a.Id)
        // setLatestBlogs(sortedBlogs.slice(0, 5))
        setLatestBlogs(sortedBlogs)
    }, [])

    const getContentObject = () => {
        if (editData && Object.entries(editData).length > 0) {
            const contentTypeDTO = JSON.parse(editData?.ContentTypeDTO?.ContentObject);
            Object.entries(contentTypeDTO).forEach(data => {
                contentTypeDTO[data[0]] = data[1]?.value || data[1];
            });
            const editDataContentObject = JSON.parse(editData?.ContentObject);
            return JSON.stringify({ ...contentTypeDTO, ...editDataContentObject });
        }
    }

    return (
        <div id="news-page" className={`section`} style={{
            background: `url(${sectionContentObj?.mainBackgroundImage?.url}) 50% no-repeat`,
            backgroundSize: "cover"
        }}>
            <img className='news-image' src={sectionContentObj?.heroImage?.url} alt={sectionContentObj?.heroImage?.name} />
            <img className='mobile-news-image' src={sectionContentObj?.mobileHeroImage?.url} alt={sectionContentObj?.mobileHeroImage?.name} />
            {/* <img className='news-title-img' src={sectionContentObj?.titleImage?.url} width={"100%"} /> */}
            <div className='news-title-img m-0'><h5 className='title-text' style={{ color: sectionThemeObj ? `${sectionThemeObj?.title}` : "" }} >{sectionContentObj?.title.toUpperCase()}</h5></div>
            <div className='details-wrap'>
                <img className='w-100' src={sectionContentObj?.borderImage?.url} alt={sectionContentObj?.borderImage?.name} />
                <div className='content-parent-wrap'>
                    <div className='content-wrap'>
                        {latestBlogs?.length &&
                            latestBlogs?.map((item, index) => (<div key={`List${index}`} className='content-block'>
                                <div className='content-date' style={{ color: sectionThemeObj ? `${sectionThemeObj?.subTitle}` : "" }}>{item?.Blog?.effectiveDate && moment(item?.Blog?.effectiveDate).format('YYYY/MM/DD')}</div>
                                <div onClick={() => navigate(`/blog/${item?.Id}`, { state: { screenPath: zoneContent?.[0]?.ScreenDTO?.Path } })} className='actual-content'>
                                    <div className='content-title' style={{ color: sectionThemeObj ? `${sectionThemeObj?.title}` : "" }}>{item?.Blog?.title}</div>
                                    <div className='content-subTitle' style={{ color: sectionThemeObj ? `${sectionThemeObj?.subTitle}` : "" }}>{item?.Blog?.subTitle}</div>
                                </div>
                            </div>))
                        }
                    </div>
                </div>
            </div>
            <div className="news-details-block">
                <img src={sectionContentObj?.borderImage?.url} className="line" />
                <div className="news-content">
                    <div>
                        {latestBlogs?.length && latestBlogs?.map((list, index) => (
                            <div key={`list_${index}`} className="news-block">
                                <div className="cell-date"  style={{ color: sectionThemeObj ? `${sectionThemeObj?.subTitle}` : "" }}>{list?.Blog?.effectiveDate}</div>
                                <div onClick={() => navigate(`/blog/${list?.Id}`, { state: { screenPath: zoneContent?.[0]?.ScreenDTO?.Path } })} className="cell-content">
                                    <div className="title" style={{ color: sectionThemeObj ? `${sectionThemeObj?.title}` : "" }}>{list?.Blog?.title}</div>
                                    <div className="sub-title" style={{ color: sectionThemeObj ? `${sectionThemeObj?.subTitle}` : "" }}>{list?.Blog?.subTitle}</div>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsSection