import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCards, Thumbs } from 'swiper/modules';
import "swiper/css"
import 'swiper/css/thumbs';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import ImageModal from './image-modal';

const AdventureGameFeature = ({ zoneContent, isGameFeatureModalOpen, setIsGameFeatureModalOpen, sectionThemeObj }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [gameFeatureData, GameFeatureData] = useState(false);
    const sectionContentObj = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)
    const navigationNextRef = useRef(null);
    const navigationPrevRef = useRef(null);

    const highlightActiveIcon = () => {
        const prevDOMNode = document.getElementsByClassName("prev-highlight-dom-node")[0]
        const activeBullet = document.getElementsByClassName('swiper-pagination-bullet-active');
        if (!prevDOMNode) {
            if (activeBullet.length > 0) {
                activeBullet[0].src = sectionContentObj?.activePaginationIcon?.url
                activeBullet[0].classList.add('prev-highlight-dom-node')
            }
        } else {
            const prevDOMNode = document.getElementsByClassName("prev-highlight-dom-node")[0];
            prevDOMNode.src = sectionContentObj?.paginationIcon?.url;
            prevDOMNode.classList.remove("prev-highlight-dom-node");
            activeBullet[0].src = sectionContentObj?.activePaginationIcon?.url
            activeBullet[0].classList.add('prev-highlight-dom-node')
        }
    }

    return (
        <div id="game-feature-page" className={`section`} style={{
            background: `url(${sectionContentObj?.backgroundImage.url}) 50% no-repeat`,
            backgroundSize: "100% 100%"
        }}>
            <img src={sectionContentObj?.mobileBackgroundImage?.url} className='mobile-background' />
            <div className='game-feature-block'>
                {/* <img src={sectionContentObj?.titleImage.url} className='feature-title' /> */}
                <div className='feature-title'><h5 className='title-text' style={{ color: sectionThemeObj ? `${sectionThemeObj?.title}` : "" }}>{sectionContentObj?.title.toUpperCase()}</h5></div>
                <div className='game-feature-content'>
                    <Swiper
                        slidesPerView={1}
                        className='card-swiper'
                        effect='cards'
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current;
                            swiper.params.navigation.nextEl = navigationNextRef.current;
                        }}
                        loop={true}
                        grabCursor
                        centeredSlides={true}
                        cardsEffect={{
                            perSlideOffset: 10,
                            perSlideRotate: 2,
                            rotate: true,
                            slideShadows: true
                        }
                        }
                        onSlideChange={highlightActiveIcon}
                        pagination={{
                            clickable: true,
                            renderBullet: function (index, className) {
                                return `<img class=${className} src=${sectionContentObj?.paginationIcon?.url} />`
                            }
                        }}
                        modules={[Navigation, Pagination, EffectCards]}
                    >
                        {sectionContentObj?.images.map((image, i) => (
                            <SwiperSlide key={`Feature${i}`}>
                                <img
                                    src={image?.url}
                                    width={"100%"}
                                    onClick={() => {
                                        GameFeatureData(image)
                                        setIsGameFeatureModalOpen(true)
                                    }}
                                />
                            </SwiperSlide>
                        ))}
                        <div ref={navigationPrevRef} className='swiper-button-prev'><img className='w-100' src={sectionContentObj?.prevSlide.url} /></div>
                        <div ref={navigationNextRef} className='swiper-button-next'><img className='w-100' src={sectionContentObj?.nextSlide.url} /></div>
                    </Swiper>
                </div>
                <Swiper
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[Thumbs]}
                    className="mobile-swiper"
                >
                    {sectionContentObj?.images.map((image, i) => (<SwiperSlide key={`Feature${i}`}><img src={image?.url} width={"100%"} /></SwiperSlide>))
                    }
                </Swiper>
                <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={5}
                    slidesPerView={5}
                    modules={[Thumbs]}
                    className="mobile-thumb-swiper"
                >
                    {sectionContentObj?.images.map((image, i) => (<SwiperSlide key={`Feature${i}`}>
                        <div className='thumb'>
                            <img src={image?.url} />
                        </div>
                    </SwiperSlide>))
                    }
                </Swiper>
            </div>
            {isGameFeatureModalOpen && <ImageModal
                isOpen={isGameFeatureModalOpen}
                setOpen={() => setIsGameFeatureModalOpen(false)}
                size={"lg"}
                centered
                className='image-modal'
                message={gameFeatureData}
            />}
        </div>
    )
}

export default AdventureGameFeature