import React, { useEffect, useRef, useState } from 'react'
import ModalVideo from "react-modal-video";
import { Modal } from 'reactstrap';
import "react-modal-video/css/modal-video.min.css";

const AdventureExplore = ({ zoneContent, isExploreeModalOpen, setIsExploreeModalOpen, sectionThemeObj }) => {
    const [isActive, setIsActive] = useState({})
    const [isActiveVideo, setIsActiveVideo] = useState(false);
    const [isActiveDetails, setIsActiveDetails] = useState(false)
    const sectionContentObj = JSON.parse(zoneContent[0]?.ContentDTO?.ContentObject)
    const firstImageRef = useRef(null)
    const adventureBlockRef = useRef(null)
    
    const resize = () => {
        if (window?.innerWidth < 1441) {
            adventureBlockRef.current.scrollLeft = Math.abs(firstImageRef?.current?.offsetWidth - window.innerWidth) / 2
        }
    }
    useEffect(() => {
        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize)
        }
    }, [])

    const mapOnClick = (name) => {
        setIsExploreeModalOpen(true)
        const activeDetails = sectionContentObj?.dynamicFieldList?.find((item) => item.name === name)
        if (activeDetails?.video?.url) {
            setIsActiveDetails(false)
            setIsActiveVideo(activeDetails?.video?.url)
        } else if (activeDetails?.details) {
            setIsActiveVideo(false)
            setIsActiveDetails(activeDetails?.details)
        }
    }

    const handleMouseEvent = (event, name) => {
        const activeDynamicList = sectionContentObj?.dynamicFieldList?.find((item) => item.name === name)
        activeDynamicList ? setIsActive({ [`${name}`]: event === "onMouseOver" ? true : false }) : setIsActive({ [`${name}`]: event === "onMouseOver" ? false : true })
    }

    const handleAspectRatio = (coords) => {
        const heightOfScreen = firstImageRef?.current?.naturalHeight;
        const widthOfScreen = firstImageRef?.current?.naturalWidth;
        return coords.map((coord,index) => 
            index % 2 === 0 ?  Math.ceil(coord*(widthOfScreen/1000)) : Math.ceil(coord*(heightOfScreen/610))).join(",");  
    }

    const areaCollection = (screen) => {
        return (
            <map name="image-map" >
            {sectionContentObj?.map?.coordinates?.map((items) => 
                    <area className={`${screen === 'desktop' ? 'desktop-area' : 'mobile-area'}`} onMouseOver={() => handleMouseEvent("onMouseOver", items.name)}
                        onMouseLeave={() => handleMouseEvent("onMouseLeave", items.name)} coords={handleAspectRatio(items.coords)} shape="poly" onClick={() => mapOnClick(items.name)} />
                )}
            </map>
        )
    };

    return (
        <div id="explore-page" className='section'>
            {/* <div className='frame-box' style={{
                backgroundImage: `url(${sectionContentObj?.frameImage.url})`
            }}></div>
            <div className='cloud-right' style={{
                background: `url(${sectionContentObj?.cloudImage1.url}) 0 0 no-repeat`,
                backgroundSize: 'cover'
            }}></div>
            <div className='cloud-left' style={{
                background: `url(${sectionContentObj?.cloudImage2.url}) 0 0 no-repeat`,
                backgroundSize: 'cover'
            }}></div> */}
            <div ref={adventureBlockRef} className={`adventure-block`}>
                {areaCollection('desktop')}
                <div id="map">
                    <img ref={firstImageRef} onLoad={() => resize()} className='map map-en' useMap='#image-map' src={sectionContentObj?.map.url} />
                </div>
                <div className='active-map-collection'>
                    {sectionContentObj?.dynamicFieldList?.map((image, index) => (
                        <img src={image?.image?.url} key={`image_${index}`} useMap='#image-map' className={`active-map ${isActive?.[image.name] ? 'active' : ''} active-${image.name}`} />))
                    }
                </div>
                <div className='image-pan'>
                    {areaCollection('mobile')}
                    <img onLoad={() => resize()} src={sectionContentObj?.map?.url} ref={firstImageRef} useMap='#image-map' />
                    {sectionContentObj?.dynamicFieldList?.map((image, index) => (
                        <img src={image?.image?.url} key={`image_${index}`} useMap='#image-map' className={`active-map ${isActive?.[image.name] ? 'active' : ''} active-${image.name}`} />
                    ))
                    }
                </div>
            </div>
            <div className='section-title m-0'>
                <h5 className='title-text' style={{ color: sectionThemeObj ? `${sectionThemeObj?.title}` : "" }}>{sectionContentObj?.title.toUpperCase()}</h5>
            </div>
            <div className='caption-title' style={{ color: sectionThemeObj ? `${sectionThemeObj?.description}` : "" }}>{sectionContentObj?.titleCaption}</div>
            {
                isActiveVideo &&
                ((isActiveVideo?.includes("https://youtu.be/") || isActiveVideo?.includes("www.youtube.com")) ?
                    <ModalVideo
                        channel="youtube"
                        videoId={
                            isActiveVideo.includes("https://youtu.be/") ?
                                isActiveVideo.match(/youtu\.be\/([^\?]+)/)[1] :
                                isActiveVideo.includes("watch") ? url.split('=')[1] :
                                    isActiveVideo.split("/embed/")[1]
                        }
                        autoplay
                        loop="1"
                        isOpen={isExploreeModalOpen}
                        onClose={() => setIsExploreeModalOpen(false)}
                    /> :
                    <ModalVideo
                        channel="custom"
                        url={isActiveVideo}
                        autoplay
                        isOpen={isExploreeModalOpen}
                        onClose={() => setIsExploreeModalOpen(false)}
                    />)
            }
            {
                isActiveDetails &&
                <Modal
                    isOpen={isExploreeModalOpen}
                    toggle={() => setIsActiveDetails(false)}
                    size='lg'
                    centered
                >
                    <button onClick={() => setIsActiveDetails(false)} class="modal-video-close-btn" aria-label="Close the modal by clicking here"></button>
                    <div className='d-flex flex-column align-items-center ' style={{ padding: "2rem", margin: "0px" }}>
                        <div className='description' dangerouslySetInnerHTML={{ __html: isActiveDetails }} />
                    </div>
                </Modal>
            }
        </div>
    )
}

export default AdventureExplore