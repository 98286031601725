import React, { Fragment, useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { SearchContext } from "@/context/search-context";
import { MenuContext } from "@/context/menu-context";
import SearchPopup from "@/components/search-popup";
import PopupMenu from "@/components/popup-menu";
import ZweihanderPopup from "@/components/zweihander-popup-menu";
import { Link as ScrollLink } from "react-scroll";

import "typeface-oswald";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/css/animate.css";
import "@/css/font-awesome.min.css";
import "@/css/icons.css";
import "@/css/preset.css";
import "@/css/theme.css";
import "@/css/responsive.css";
import "@/css/epics.css";
import "@/css/systems.css";

const zweihanderKeywords = "Zweihander, Reforged, Zweihander Reforged, zweihanderreforged, reforged zweihander, zweihander reforged edition, zweihander edition, reforged edition"
const wogdKeywords = "Wogd, World of Game Design, WOGD Home Page, worldofgamedesign, Game Design"

const Layout = ({ PageTitle, webLogo, webURL, children, sectionThemeObj }) => {
  const { searchStatus } = useContext(SearchContext);
  const { menuStatus } = useContext(MenuContext);
  const { script } = useContext(MenuContext);
  const { reforgedMenuStatus } = useContext(MenuContext);
  const [scrollTop, setScrollTop] = useState(false);
  const renderScript = script?.ContentObject && JSON.parse(script?.ContentObject)
  const location = (typeof window !== 'undefined') && window.location;
  const isZweihanderWeb = location.origin === "https://www.zweihanderreforged.com" || location.origin === "https://zweihanderreforged.com"
  const handleScrollTop = () => {
    if (window !== undefined) {
      if (window.scrollY > 70) {
        setScrollTop(true);
      } else if (window.scrollY < 70) {
        setScrollTop(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollTop);
    return () => {
      window.removeEventListener("scroll", handleScrollTop);
    };
  }, [scrollTop]);

  return (
    <Fragment>
      <Helmet>
        <script>
          {renderScript?.script}
        </script>
        <title>{isZweihanderWeb ? "Zweihander Reforged" : "World of Game Design"} - {PageTitle?.MetaTitle ? `${PageTitle?.MetaTitle}` : `${PageTitle?.Name}`}</title>
        <link rel="canonical" href={isZweihanderWeb ? location.origin : "https://www.wogd.com/"} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="application-name" content={isZweihanderWeb ? "Zweihander Reforged" : "World of Game Design"} />
        <meta name="title" content={PageTitle?.MetaTitle && PageTitle?.MetaTitle} />
        <meta name="description" content={PageTitle?.MetaDescription && PageTitle?.MetaDescription} />
        <meta name="keywords" content={isZweihanderWeb ? zweihanderKeywords : wogdKeywords} />
        <meta name="image" content={isZweihanderWeb ? "https://imagedelivery.net/onif3zXUwTfVGFex872VJw/ab6ae294-499b-4bab-78a5-e989094ab700/thumbnail" : "https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms//thumbnail/20240307091659050_20240206034755102_LogoWOGD.png"} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={isZweihanderWeb ? location.origin : "https://www.wogd.com/"} />
        <meta property="og:title" content={isZweihanderWeb ? "Zweihander Reforged" : "World of Game Design"} />
        <meta property="og:description" content={`Enter a realm of epic stories that explore endless worlds using the latest artists, writers, and technologies.  Whether you love miniatures, Roleplaying Games (RPGS), terrain, 3D printing, comics, board games, or card games; we think you will find something to entertain you at the ${isZweihanderWeb ? "Zweihander Reforged" : "World of Game Design"}`} />
        <meta property="og:image" content={isZweihanderWeb ? "https://imagedelivery.net/onif3zXUwTfVGFex872VJw/ab6ae294-499b-4bab-78a5-e989094ab700/thumbnail" : "https://res.cloudinary.com/wogd/image/upload/v1710328579/site/media/wogd_logo_thumbnail/u6ctieawh8be4cg4f1xy.jpg"} />
        <meta property="og:site_name" content={isZweihanderWeb ? "Zweihander Reforged" : "World of Game Design"} />
        <meta property="fb:app_id" content={isZweihanderWeb ? "400174196103578" : "1332362987428583"} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={isZweihanderWeb ? "https://imagedelivery.net/onif3zXUwTfVGFex872VJw/ab6ae294-499b-4bab-78a5-e989094ab700/thumbnail" : "https://www.wogd.com/static/logoWOGD-Bright-No-Shadow-6e4a0da5fe54649e236e8a3f451a59a0.png"} />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={isZweihanderWeb ? location.origin : "https://www.wogd.com/"} />
        <meta property="twitter:title" content={isZweihanderWeb ? "Zweihander Reforged" : "World of Game Design"} />
        <meta property="twitter:description" content={`Enter a realm of epic stories that explore endless worlds using the latest artists, writers, and technologies.  Whether you love miniatures, Roleplaying Games (RPGS), terrain, 3D printing, comics, board games, or card games; we think you will find something to entertain you at the ${isZweihanderWeb ? "Zweihander Reforged" : "World of Game Design"}`} />
        <meta property="twitter:image" content={isZweihanderWeb ? "https://imagedelivery.net/onif3zXUwTfVGFex872VJw/ab6ae294-499b-4bab-78a5-e989094ab700/thumbnail" : "https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/cms//thumbnail/20240307091659050_20240206034755102_LogoWOGD.png"} />
      </Helmet>
      <div id="wrapper">{children}</div>
      {searchStatus ? <SearchPopup /> : null}
      {menuStatus ? <PopupMenu /> : null}
      {reforgedMenuStatus ? <ZweihanderPopup sectionThemeObj={sectionThemeObj} /> : null}
      {scrollTop === true ? (
        <ScrollLink
          to="wrapper"
          smooth={true}
          duration={500}
          id="backToTop"
          className="scroll-to-top showit"
        >
          <i className="fa fa-angle-double-up"></i>
        </ScrollLink>
      ) : null}
    </Fragment>
  );
};

export default Layout;
